import React from 'react'
import { Layout, Stack, Main } from '@layout'
import Seo from '@widgets/Seo'
import WeatherComponent from '@widgets/HeroWeather'


const Weather = props => {

    return (
        <>
            <Layout {...props}>
                <Seo title='Local Weather Forecast'/>
                <WeatherComponent />
            </Layout>
        </>
    )
}

export default Weather;
